import React, { useEffect, useState, useRef } from "react"
import Swiper from "swiper"
import Constants from "../constants/api"
import Layout from "../components/layout"
import Loader from "../components/loader"
import Join from "../components/join"
import SwiperSlide from "../components/swiperslide"
import SwiperSlideRef from "../components/swiperslideref"
import SEO from "../components/seo"
import { GetParameterByName } from "../helpers"
import "./river.css"

function RiverSlider(props) {
  const g = useRef(null)
  const videosPerLoad = 12
  const slug = props.pageContext.slug
  const snapchat = props.pageContext.freesnapchat
  const modelName = props.pageContext.model_name
  const options = props.pageContext.options
  const siteid = props.pageContext.siteid
  const maybe = useRef(null)
  const [isLoading, setIsLoading] = useState(true)
  const [slideInfo, setSlideInfo] = useState("0.0")
  const [showSnapCode, setShowSnapCode] = useState(false)
  const [allSlides, setAllSlides] = useState([])
  const [currentLoc, setCurrentLoc] = useState({ start: 0, end: videosPerLoad })
  const [currentNats, setCurrentNats] = useState(props.pageContext.def_nats)
  const [isJoin, setIsJoin] = useState(false)
  const popSnapCode = _ => {
    setShowSnapCode(true)
  }
  const checkForshowSnap = () => {
    if ("location" in props) {
      if (props.location.hash === "#snapchat") {
        setShowSnapCode(true)
      }
    }
  }
  const increaseSlides = () => {
    const makeSmaller = allSlides.slice(currentLoc.start, currentLoc.end)
    if (makeSmaller.length <= 0) {
      setIsJoin(true)
      return
    }
    setCurrentLoc(c => {
      return { start: c.start + videosPerLoad, end: c.end + videosPerLoad }
    })
    setVirtualData({
      ...virtualData,
      slides: [...virtualData.slides, ...makeSmaller],
    })
  }
  useEffect(() => {
    if (isJoin) {
      document.body.classList.add("show_form")
    } else {
      document.body.classList.remove("show_form")
    }

    return () => document.body.classList.remove("show_form")
  }, [isJoin])
  const changeOutNats = () => {
    let natsLink = GetParameterByName("nats")
    if (!!natsLink) {
      localStorage.setItem("nats_af", natsLink)
      setCurrentNats(natsLink)
      return
    }
    if (!!localStorage.getItem("nats_af")) {
      setCurrentNats(localStorage.getItem("nats_af"))
      return
    }
    return
  }
  const handleClick = riverID => {
    if (
      maybe &&
      "current" in maybe &&
      maybe.current &&
      "realIndex" in maybe.current
    ) {
      setSlideInfo(`${riverID}.${maybe.current.realIndex}`)
    } else {
      setSlideInfo(`0.0`)
    }
    setIsJoin(j => !j)
  }

  const [virtualData, setVirtualData] = useState({
    offset: 0,
    from: 0,
    to: 0,
    slides: [],
    isDesktop: false,
  })

  useEffect(() => {
    changeOutNats()
    checkForshowSnap()
    const AFSwiper = data => {
      setIsLoading(false)
      if (g.current) {
        maybe.current = new Swiper(g.current, {
          direction: "vertical",
          preloadImages: false,
          on: {
            init() {
              setIsLoading(false)
            },
          },
          virtual: {
            slides: data,
            renderExternal(data) {
              setVirtualData(data)
            },
            addSlidesBefore: 1,
            addSlidesAfter: 1,
          },
        })
      }
    }
    fetch(`${Constants.RIVER}model-river/${siteid}`)
      .then(res => res.json())
      .then(res => {
        const width =
          window.innerWidth ||
          document.documentElement.clientWidth ||
          document.getElementsByTagName("body")[0].clientWidth
        if (width <= 768) {
          AFSwiper(res)
        } else {
          setAllSlides(res)
          const makeSmaller = res.slice(0, currentLoc.end)
          setCurrentLoc(c => {
            return {
              start: c.start + videosPerLoad,
              end: c.end + videosPerLoad,
            }
          })
          setVirtualData({ slides: [...makeSmaller], isDesktop: true })
          setIsLoading(false)
        }
      })
  }, [])
  return (
    <Layout isRiver={true}>
      <SEO title={`${modelName} Videos`} can={`${slug}/river`} />
      {isLoading ? <Loader /> : null}
      <div className="river swiper-container" ref={g}>
        <div className="swiper-wrapper">
          {virtualData.isDesktop
            ? virtualData.slides.map(river => {
                return (
                  <SwiperSlideRef
                    key={`${river.id}rhm`}
                    river={river}
                    topOffSet={virtualData.offset}
                    handleClick={() => handleClick(`${river.id}`)}
                    popSnapCode={popSnapCode}
                  />
                )
              })
            : virtualData.slides.map(river => {
                return (
                  <SwiperSlide
                    key={`${river.id}rhm`}
                    river={river}
                    topOffSet={virtualData.offset}
                    handleClick={() => handleClick(`${river.id}`)}
                    popSnapCode={popSnapCode}
                  />
                )
              })}
        </div>
        {virtualData.isDesktop ? (
          <span className="loadMore" onClick={increaseSlides}>
            <span>
              <b>Load More</b>
              <small>{videosPerLoad} new videos loaded at a time</small>
            </span>
          </span>
        ) : null}
      </div>
      <Join
        handleClick={handleClick}
        slug={slug}
        siteid={siteid}
        modelName={modelName}
        def_nats={currentNats}
        options={options}
        theSlideInfo={slideInfo}
      />
      {showSnapCode ? (
        <div className="showSnapCode" onClick={() => setShowSnapCode(false)}>
          <i className="icon-close" onClick={() => setShowSnapCode(false)} />
          <img src={`/${slug}-snapchat.svg`} alt="" />
          <h2>
            My Free Snapchat Username is: <strong>{snapchat}</strong>
          </h2>
        </div>
      ) : null}
    </Layout>
  )
}

export default RiverSlider
